<template>
  <auth-checker :accessRoles="['SuperAdmin', 'InstructorAdmin']">
    <ui-card
      v-loading="loading"
      :heading="`${$t('actions.create')} ${$tc('models.event')}`"
    >
      <course-event-form
        :registration-banner="registrationBanner"
        :course-event="courseEvent"
        @submit="handleSubmit"
        @cancel="handleCancel"
      ></course-event-form>
    </ui-card>
  </auth-checker>
</template>

<script>
import UiCard from "@/components/ui/UiCard";
import CourseEventForm from "../components/CourseEventForm";
import { SupportedLanguages } from "@/i18n/lang";
import { postCourseEvent } from "../api";
import AuthChecker from "../../auth/components/AuthChecker";
import { uploadRegistrationBanner } from "../api";

export default {
  data() {
    return {
      registrationBanner: {},
      courseEvent: {
        slug: null,
        title: null,
        description: null,
        internalDescription: null,
        certificateDescription: null,
        instructor: null,
        language: this.getFirstSupportedLang(), // Default Norwegian
        sessions: [],
        field1: null,
        field2: null,
        certificateLogo: null,
        registrationBanner: null,
        active: true,
      },
      loading: false,
    };
  },
  components: {
    UiCard,
    CourseEventForm,
    AuthChecker,
  },

  methods: {
    getFirstSupportedLang() {
      return JSON.parse(JSON.stringify(SupportedLanguages))[0].value;
    },

    showUploadImageSuccess() {
      this.$message({
        message: this.$t("server_feedback.image_upload_success").toString(),
        type: "success",
      });
    },
    showUploadImageFailure() {
      this.$message({
        message: this.$t("server_feedback.image_upload_failure").toString(),
        type: "error",
      });
    },
    showCreatedCourseEventSuccess() {
      this.$message({
        message: this.$t("server_feedback.course_event_created").toString(),
        type: "success",
      });
    },
    showGenericError() {
      this.$message({
        message: this.$t("errors.general").toString(),
        type: "error",
      });
    },

    /**
     * Handles image upload
     * @param {string} imagecourseEventId The course event to "attach" the image too
     * @returns {boolean} true if successful (no errors), false if errors occured
     */
    uploadImage: async function (courseEventId) {
      try {
        const response = await uploadRegistrationBanner(
          this.registrationBanner.value.raw,
          courseEventId
        );

        return response.status === 200;
      } catch (err) {
        console.error(err);
        return false;
      }
    },

    /**
     * Saves the course event.
     * @returns {Object} The course event
     */
    saveCourseEvent: async function () {
      try {
        this.courseEvent.active = true;
        const response = await postCourseEvent(this.courseEvent);

        if (response.status === 200) {
          return response.data;
        }
      } catch (err) {
        console.error(err);
        return null;
      }
    },

    handleSubmit: async function (imageAction) {
      this.loading = true;

      // We have to save course first in order to get a valid id for our image upload
      const savedCourseEvent = await this.saveCourseEvent();
      if (savedCourseEvent) {
        this.showCreatedCourseEventSuccess();
      } else {
        this.showGenericError();
        return;
      }

      // Course save went OK, now we can use new id to save image
      const shouldUploadImage = imageAction === "upload";
      if (shouldUploadImage) {
        const success = await this.uploadImage(savedCourseEvent.id);
        if (success) {
          this.showUploadImageSuccess();
        } else {
          this.showUploadImageFailure();
          return;
        }
      }

      this.loading = false;
      this.waitAndGoBack();
    },

    waitAndGoBack() {
      // Waiting 1000ms so we can show toast before returning
      new Promise(() =>
        setTimeout(() => {
          this.$router.back();
        }, 1000)
      );
    },

    handleCancel() {
      this.$router.back();
    },
  },

  mounted() {
    this.$store.commit("changeBreadcrumbs", [
      { name: "models.event", link: "/app/courseevent/" },
      { name: "actions.create", link: "" },
    ]);
  },
};
</script>
